import { Injectable } from '@angular/core';
import { NgxFirebaseService } from '@azoup/ngx-firebase';
import { NgxFirebaseAuthService, UsuarioSchema as NgxUsuarioSchema } from '@azoup/ngx-firebase-auth';
import { Observable } from 'rxjs';
import { OrganizacaoCollection } from '../../_firebase/organizacao/organizacao.collection';
import { UsuarioDoc } from '../../_firebase/usuario/usuario.doc';
import { CurrentUser } from './auth.current';


@Injectable({ providedIn: 'root' })
export class AuthService {

    public redirectUrl: string;
    public readonly currentUser: CurrentUser;

    private subs: any[] = [];
    private _initialized: boolean;

    get user() {
        return this.currentUser.user;
    }

    constructor(
        private ngxFirebaseService: NgxFirebaseService,
        private ngxFirebaseAuthService: NgxFirebaseAuthService<NgxUsuarioSchema>,
        private organizacaoCollection: OrganizacaoCollection,
    ) {
        console.log(this.uid);
        this.currentUser = new CurrentUser(this.ngxFirebaseService);
        this.observeCurrentUser().subscribe();
    }

    public observeCurrentUser(): Observable<CurrentUser> {
        return new Observable<CurrentUser>(sub => {
            const load = async () => {
                await this.loadCurrentUser();
                sub.next(this.currentUser);
            }
            this.ngxFirebaseAuthService.onAuthStateChanged(a => load());
            this.ngxFirebaseAuthService.onIdTokenChanged(a => load())
        });
    }



    public init(): Promise<boolean> {
        if (this._initialized)
            return Promise.resolve(true);

        return new Promise<boolean>(resolve => setTimeout(() => resolve(this.init()), 250));
    }

    public async loadCurrentUser(): Promise<void> {
        if (this.ngxFirebaseAuthService.isLogged()) {
            const user = (await this.ngxFirebaseAuthService.getUser()) as unknown as UsuarioDoc;
            user.data.ultimoAcesso = this.ngxFirebaseService.serverTimestamp();
            if (user.data.email.contains('@azoup.com.br') && !user.data.roles) {
                user.data.roles = { ADMIN: true };
            }

            await user.ref.set(user.data, { merge: true });
            this.currentUser.user = user;
            this.currentUser.user.onLine();
        } else {
            this.currentUser.clear();
        }
        this._initialized = true;
    }

    public async setOrganizacao(organizacaoId: string): Promise<any> {
        this.currentUser.organizacao = undefined;
        if (!organizacaoId) { return; }

        const organizacao = await this.organizacaoCollection.get(organizacaoId);
        if (!organizacao) {
            console.warn(`[organizacao:${organizacaoId}] not found`);
            return Promise.reject('Organização não encontrada');
        }
        this.currentUser.organizacao = organizacao;
    }


    public isLogged(): boolean {
        if (!this.currentUser.user) {
            return false;
        }
        return true;
    }

    public async logout(): Promise<void> {
        this.currentUser.clear();
        this.subs.map((s: any) => s.unsubscribe ? s.unsubscribe() : s());
        this.subs = [];
        await this.ngxFirebaseAuthService.logout();
    }
}
