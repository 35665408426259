import packageInfo from '../../package.json';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    name: `${packageInfo.name}-dev`,
    version: packageInfo.version,
    productName: 'EnterprisePlatform',
    production: false,
    firebase: {
        config: {
            apiKey: 'AIzaSyCD3mJesHyys44bgZSpxcVMs2iqzOMvo70',
            authDomain: 'az-carrinhodecompras-dev-01.firebaseapp.com',
            databaseURL: 'https://az-carrinhodecompras-dev-01.firebaseio.com',
            projectId: 'az-carrinhodecompras-dev-01',
            storageBucket: 'az-carrinhodecompras-dev-01.appspot.com',
            messagingSenderId: '678971134304',
            appId: '1:678971134304:web:9a82b837b2d94d77388e05',
            measurementId: 'G-VLS5DYZ18S'
        }
    },
    // firebase: {
    //     config: {
    //         apiKey: "AIzaSyDctb37MNhh0r9lC3n-Ic-VL1v8kR_vY_c",
    //         authDomain: "az-carrinhodecompras-prod.firebaseapp.com",
    //         databaseURL: "https://az-carrinhodecompras-prod.firebaseio.com",
    //         projectId: "az-carrinhodecompras-prod",
    //         storageBucket: "az-carrinhodecompras-prod.appspot.com",
    //         messagingSenderId: "415060561736",
    //         appId: "1:415060561736:web:e22cbc00adec689dfd6d7d",
    //         measurementId: "G-JFKEF0V5T5"
    //     }
    // },
    api: {
        // email: "https://localhost:5001"
        email: "https://api.email.azoup.app",
        // log: "https://localhost:5001"
        log: "https://api.log.azoup.app"
    }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
