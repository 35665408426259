export type TAppIcons =
    | 'facebook'
    | 'whatsapp'
    | 'instagram'
    | 'carrinho'
    | 'cesta'
    | 'google'
;


export const APP_NAME = "Enterprise Platform"

export const APP_CLICK_TIMEOUT = 750;
export const APP_CLICK_DEBOUNCE_TIMEOUT = 333;

export const APP_ICONS: Record<TAppIcons, string> = {
    carrinho: 'carrinho',
    cesta: 'cesta-de-compras',
    facebook: 'facebook',
    google: 'google-color',
    instagram: 'instagram',
    whatsapp: 'whatsapp',
};

export const APP_LOGO_URL = '/assets/logo/azp-logo_imagem.svg';
export const APP_LOGO_URL_DEFAULT = '/assets/logo/azp-logo_imagem.svg';
