import { Injectable } from '@angular/core';
import { NgxFirebaseCollection, NgxFirebaseDoc, NgxFirebaseService } from '@azoup/ngx-firebase';
import { TApplications } from 'src/app/_services/versao.service';
import { AplicacaoDoc } from '../aplicacao/aplicacao.doc';
import { OrganizacaoAplicacaoDoc } from './aplicacao/organizacao-aplicacao.doc';
import { OrgFreteDoc } from './frete/frete.doc';
import { OrganizacaoDoc } from './organizacao.doc';
import { OrganizacaoSchema } from './organizacao.schema';
import { OrganizacaoUsuarioDoc } from './usuario/organizacao-usuario.doc';
import { OrganizacaoUsuarioSchema } from './usuario/organizacao-usuario.schema';

@Injectable({ providedIn: 'root' })
export class OrganizacaoCollection extends NgxFirebaseCollection<OrganizacaoSchema> {

    private firebase: NgxFirebaseService;

    constructor(fb: NgxFirebaseService) {
        super('/organizacao', fb, (snap) => new OrganizacaoDoc(snap) as NgxFirebaseDoc<OrganizacaoSchema>);
        this.firebase = fb;
    }

    async getUsuario(organizacaoId: string, userKey: string) {
        return this.collection.doc(organizacaoId).collection('/usuario')
            .doc(userKey)
            .get().then(snaps => snaps.exists ? new OrganizacaoUsuarioDoc(snaps) : null);
    }

    async getUsuarioByEmail(organizacaoId: string, email: string) {
        return this.collection.doc(organizacaoId).collection('/usuario')
            .where('email', '==', email).get()
            .then(snaps => (snaps.docs || []).map(snap => new OrganizacaoUsuarioDoc(snap)))
    }

    async editUsuario(organizacaoId: string, usuarioId: string, raw: OrganizacaoUsuarioSchema) {
        (raw as any).cadastro = this.firebase.serverTimestamp();
        Object.keys(raw).forEach(k => raw[k] === undefined && delete raw[k]);
        return this.collection.doc(organizacaoId)
            .collection('/usuario')
            .doc(usuarioId).set(raw);
    }

    async addUsuario(organizacaoId: string, raw: OrganizacaoUsuarioSchema) {
        (raw as any).cadastro = this.firebase.serverTimestamp();
        Object.keys(raw).forEach(k => raw[k] === undefined && delete raw[k]);
        return this.collection.doc(organizacaoId)
            .collection('/usuario')
            .doc().set(raw);
    }

    async deleteUsuario(organizacaoId: string, usuarioId: string): Promise<void> {
        return this.collection.doc(organizacaoId)
            .collection('/usuario').doc(usuarioId).delete();
    }

    async getUsuarios(organizacaoKey: string, equals?: { [key: string]: any }) {
        let query = this.collection.doc(organizacaoKey).collection('/usuario');
        if (equals) {
            Object.keys(equals).map(k => {
                query = query.where(k, '==', equals[k]) as any;
            });
        }
        return query.get().then(snaps => snaps.empty ? [] : snaps.docs.map(d => new OrganizacaoUsuarioDoc(d)));
    }

    async getFretes(organizacaoKey: string): Promise<OrgFreteDoc[]> {
        return this.collection.doc(organizacaoKey).collection('/frete').get()
            .then(s => s.docs?.map(d => new OrgFreteDoc(d)) ?? []);
    }

    async getAplicacoes(organizacaoKey: string, types: TApplications[] = []): Promise<OrganizacaoAplicacaoDoc[]> {
        let query: any = this.collection.doc(organizacaoKey).collection('/aplicacao');
        for (const t of types)
            query = query.where('application', '==', t);

        return query.get()
            .then(snaps => snaps.empty ? [] : snaps.docs.map(d => new OrganizacaoAplicacaoDoc(d)));
    }

    async saveAplicacao(organizacaoId: string, versaoDoc: AplicacaoDoc) {
        return this.collection.doc(organizacaoId).collection('/aplicacao')
            .doc(versaoDoc.id).set({ ...versaoDoc.data, versaoRef: { id: versaoDoc.id, ref: versaoDoc.ref } });
    }

    async enableAplicacao(organizacaoId: string, aplicacaoId: string) {
        return this.collection.doc(organizacaoId).collection('/aplicacao')
            .doc(aplicacaoId).set({ habilitado: true }, { mergeFields: ['habilitado'] });
    }

    async disableAplicacao(organizacaoId: string, aplicacaoId: string) {
        return this.collection.doc(organizacaoId).collection('/aplicacao')
            .doc(aplicacaoId).set({ habilitado: false }, { mergeFields: ['habilitado'] });
    }

    async removeAplicacao(organizacaoId: string, aplicacaoId: string) {
        return this.collection.doc(organizacaoId).collection('/aplicacao')
            .doc(aplicacaoId).delete();
    }

    async getGestores(organizacaoKey: string) {
        return this.collection.doc(organizacaoKey).collection('/usuario')
            .where('roles.gestor', '==', true)
            .get().then(snaps => snaps.empty ? null : snaps.docs.map(d => new OrganizacaoUsuarioDoc(d)));
    }

    async getVendedores(organizacaoKey: string) {
        return this.collection.doc(organizacaoKey).collection('/usuario')
            .where('roles.vendedor', '==', true)
            .get().then(snaps => snaps.empty ? null : snaps.docs.map(d => new OrganizacaoUsuarioDoc(d)));
    }

    profissionais(organizacaoKey: string) {
        const query = this.collection.doc(organizacaoKey).collection('/usuario')
            .where('roles.profissional', '==', true);
        return this.execute(query);
    }

    clientes(organizacaoKey: string) {
        const query = this.collection.doc(organizacaoKey).collection('/usuario')
            .where('roles.clientes', '==', true);
        return this.execute(query);
    }

    async exist(id: string): Promise<boolean> {
        return (await this.collection.doc(id).get()).exists;
    }

    async getUsuariosFromCollectionGroupUsuarios(equals: any = { inativo: false }) {
        const collectionGroup = this.fb.db.collectionGroup('usuario');
        equals.inativo = (equals.inativo || false);
        const query = this.equal(equals, collectionGroup).query();
        return query.get().then(snaps => (snaps.docs || []).map(s => new OrganizacaoUsuarioDoc(s)));
    }


}
