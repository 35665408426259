import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxFirebaseService } from '@azoup/ngx-firebase';
import { NgxFirebaseAuthService } from '@azoup/ngx-firebase-auth';
import { NgxUiModule } from '@azoup/ngx-ui';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AppService } from './app.service';
import { FirebaseModule } from './infra/firebase/firebase.module';


registerLocaleData(localePt);

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgxUiModule,
        FirebaseModule.forRoot(environment.firebase.config),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,

            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            // registrationStrategy: 'registerWhenStable:30000'
            registrationStrategy: 'registerImmediately'
        })
    ],
    providers: [
        AppService,
        { provide: LOCALE_ID, useValue: 'pt' },
        { provide: MAT_DATE_LOCALE, useValue: 'pt' },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill', floatLabel: 'always' } },
        { provide: NgxFirebaseService, useFactory: () => new NgxFirebaseService(environment.firebase.config) },
        { provide: NgxFirebaseAuthService },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
