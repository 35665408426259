import firebase from 'firebase/compat/app';

import { Injectable } from '@angular/core';
import { NgxFirebaseService } from '@azoup/ngx-firebase';
import { CepPipe } from '@azoup/ngx-ui';
import { Throttle } from 'lodash-decorators';
import moment, { Duration, Moment } from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { EnderecoModel } from '../_models/endereco.model';

export class Util {
    static uuid(): string {
        return uuidv4();
    }

    static sleep(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    static onlyNumbers(value: string): string {
        return (value || '').replace(/\D/g, '');
    }

    static hashCode(value: string): number {
        let hash = 0;
        if (value.length === 0) { return hash; }
        for (let i = 0; i < value.length; i++) {
            // tslint:disable: no-bitwise
            const char = value.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash; // Convert to 32bit integer
        }
        return Math.abs(hash);
    }



    static urlMapsDir(endereco: EnderecoModel, pipe: CepPipe): string {
        const params = `${endereco.logradouro},${endereco.numero}-${endereco.bairro}-${endereco.municipio}-${endereco.uf}-${pipe.transform(endereco.cep)}`;
        const url = encodeURI(`https://www.google.com/maps/dir/?api=1&destination=${params}`);
        return url;
    }


    static urlMapsSearch(longitude: number, latitude: number): string {
        const params = `${latitude},${longitude}`;
        const url = encodeURI(`https://www.google.com/maps/search/?api=1&query=${params}`);
        return url;
    }

    static urlWhatsApp(whatsNumber: string, message?: string): string {
        const phone = whatsNumber.replace(/\D/g, '');
        const prefix = phone.length === 8 ?
            '+55199' : phone.length === 9 ?
                '+5519' : phone.startsWith('55') ?
                    '+' : '+55';

        const whats = encodeURI(`${prefix}${phone}`);
        const messageEncoded = encodeURIComponent(message || '');
        const url = `https://api.whatsapp.com/send?phone=${whats}&text=${messageEncoded || ''}`;
        return url;
    }

    static moment(date?: Date | moment.Moment | firebase.firestore.Timestamp, time?: string): Moment {
        // tslint:disable-next-line: no-string-literal
        const m = !date ? moment() : moment.isDate(date) ? moment(date) : date.toDate ? moment(date.toDate()) : date['clone']();
        if (time) {
            const d = Util.duration(time);
            m.set('hour', d.hours())
                .set('minute', d.minutes())
                .set('second', d.seconds())
                .set('millisecond', d.milliseconds());
        }
        return m;
    }

    static duration(value?: string | number) {
        if (typeof value === 'number') {
            return moment.duration(value);
        }
        const split = (value + '' || '0').trim().split(':');
        if (split.length > 3
            || +split[0] > 23
            || +split[1] > 59
            || +split[2] >= 60) {
            throw new Error('param time invalid: ' + value);
        }

        return moment.duration()
            .add(+split[0] || 0, 'hour')
            .add(+split[1] || 0, 'minute')
            .add(+split[2] || 0, 'second');
    }
}


/**
 * Copied over from Angular Router
 * @see https://goo.gl/8qUsNa
 */
export const NAVIGATION_CANCELING_ERROR = 'ngNavigationCancelingError';
export const DEFAULT_LOGO = '/assets/logo/agendou-logo-icone.svg';


export function debounce(inner, ms = 0) {
    let timer = null;
    let resolves = [];

    return (...args) => {
        // Run the function after a certain amount of time
        clearTimeout(timer);
        timer = setTimeout(() => {
            // Get the result of the inner function, then apply it to the resolve function of
            // each promise that has been created since the last time the inner function was run
            const result = inner(...args);
            resolves.forEach(r => r(result));
            resolves = [];
        }, ms);

        return new Promise(r => resolves.push(r));
    };
}

export function formatLeftZero(n: number) {
    return `${n >= 10 ? n : '0' + n}`;
}

export function formatDate(date: Date): string {
    return `${formatLeftZero(date.getDate())}/${formatLeftZero(date.getMonth() + 1)}/${date.getFullYear()}`;
}

export function formatHour(date: Date): string {
    return `${formatLeftZero(date.getHours())}:${formatLeftZero(date.getMinutes())}`;
}


@Injectable({ providedIn: 'root' })
export class UtilService {

    static debounceTime(inner, ms = 0) {
        let timer = null;
        let resolves = [];

        return (...args) => {
            // Run the function after a certain amount of time
            clearTimeout(timer);
            timer = setTimeout(() => {
                // Get the result of the inner function, then apply it to the resolve function of
                // each promise that has been created since the last time the inner function was run
                const result = inner(...args);
                resolves.forEach(r => r(result));
                resolves = [];
            }, ms);

            return new Promise(r => resolves.push(r));
        };
    }

    constructor(
        private firebase: NgxFirebaseService,
    ) {
        console.log(this.uid);
    }

    fbTimestampFromDate(date: Date) {
        return this.firebase.timestampFromDate(date);
    }

    fbTimestampFromMoment(m: moment.Moment) {
        return this.firebase.timestampFromDate(m.clone().toDate());
    }

    fbTimestampFromNow(): firebase.firestore.Timestamp {
        return this.fbTimestampFromMoment(this.now());
    }
    fbTimestampFromServer(): firebase.firestore.FieldValue {
        return this.firebase.serverTimestamp();
    }


    values<T = any>(obj: { [key: string]: any }) {
        if (!obj) { return [] as unknown as T; }
        return Object.keys(obj).map(k => obj[k]) as unknown as T;
    }


    mapObject(target: any = {}, obj: { id: string, ref: firebase.firestore.DocumentReference, data: any }, cb: (a) => any = a => a) {
        target[obj.id] = { id: obj.id, ref: obj.ref, data: cb(obj.data) };
        return target;
    }

    mapObjects(target: any = {}, arr: { id: string, ref: firebase.firestore.DocumentReference, data: any }[], cb: (a) => any = a => a) {
        return (arr || []).reduce((a, b) => this.mapObject(a, b), target);
    }


    /**
     * Similar to navigationCancelingError
     * @see https://goo.gl/nNd9TX
     */
    makeCancelingError(error: Error) {
        (error as any)[NAVIGATION_CANCELING_ERROR] = true;
        return error;
    }

    /**
     * Retorda a data e hora atual do sistema.
     */
    now() {
        return moment();
    }

    /**
     * Retorna a data atual com a hora zerada
     */
    @Throttle(60 * 1000)
    today() {
        return this.moment(null, '0').clone();
    }

    timer(title: string, opt: { log?: boolean } = {}) {
        const thix = ({
            _start: null,
            _end: null,
            laps: [],
            start() {
                thix._start = new Date().getTime();
                this.lap('start');
                if (opt.log) {
                    const d = new Date(thix._start);
                    const hhmmss = `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}.${d.getMilliseconds()}`;
                    console.log(`${thix.uid} Timer ${title} start at ${hhmmss}`);
                }
                return thix;
            },
            lap(name: string = '', obj: any = '') {
                const start = (thix.laps.last() || {}).end || thix._start;
                const end = new Date().getTime();
                const diff = (end - start) / 1000;
                thix.laps.add({ name, start, end, diff });
                if (opt.log) { console.log(`${thix.uid} Timer ${title} [${thix.laps.length - 1}] ${diff}s ${name}`, obj); }
                return thix;
            },
            stop(log?: boolean) {
                thix.lap('stop');
                thix._end = new Date().getTime();
                const d = new Date(thix._start);
                const hhmmss = `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}.${d.getMilliseconds()}`;
                const duracao = (thix._end - thix._start) / 1000;
                if (opt.log) { console.log(`${thix.uid} Timer ${title} end at ${hhmmss} (total: ${duracao}s)`); }
                return thix;
            },
            log() {
                const duracao = (thix._end - thix._start) / 1000;
                const start = new Date(thix._start);
                const end = new Date(thix._end);
                const formatedStart = `${start.getHours()}:${start.getMinutes()}:${start.getSeconds()}.${start.getMilliseconds()}`;
                const formatedEnd = `${end.getHours()}:${end.getMinutes()}:${end.getSeconds()}.${end.getMilliseconds()}`;

                console.log(`${thix.uid} Timer ${title} start at ${formatedStart}`);
                thix.laps.map((a, b, c) => console.log(`${thix.uid} Timer ${title} [${b + 1}/${thix.laps.length}] ${a.diff}s ${a.name}`));
                console.log(`${thix.uid} Timer ${title} end at ${formatedEnd} (total: ${duracao}s)`);
            }
        });
        return thix;
    }


    /**
     * Convert date to moment at expecific time
     */
    moment(date?: Date | moment.Moment | firebase.firestore.Timestamp, time?: string): Moment {
        // tslint:disable-next-line: no-string-literal
        const m = !date ? moment() : moment.isDate(date) ? moment(date) : date.toDate ? moment(date.toDate()) : date['clone']();
        if (time) {
            const d = this.duration(time);
            m.set('hour', d.hours())
                .set('minute', d.minutes())
                .set('second', d.seconds())
                .set('millisecond', d.milliseconds());
        }
        return m;
    }

    duration(value?: string | number) {
        if (typeof value === 'number') {
            return moment.duration(value);
        }
        const split = (value + '' || '0').trim().split(':');
        if (split.length > 3
            || +split[0] > 23
            || +split[1] > 59
            || +split[2] >= 60) {
            throw new Error('param time invalid: ' + value);
        }

        return moment.duration()
            .add(+split[0] || 0, 'hour')
            .add(+split[1] || 0, 'minute')
            .add(+split[2] || 0, 'second');
    }

    durationDiff(value1: moment.Moment, value2: moment.Moment) {
        return this.duration(value1.format('HH:mm:ss'))
            .subtract(this.duration(value2.format('HH:mm:ss')));
    }

    durationToString(duration: Duration) {
        const m = this.moment(null, '0');
        m.add(duration);
        return m.format('LT');
    }

    durationDiffToString(value1: moment.Moment, value2: moment.Moment) {
        const dur = this.durationDiff(value1, value2);
        return this.durationToString(dur);
    }

    buildAgendaDocId(date: Date | moment.Moment | firebase.firestore.Timestamp, time?: string): string {
        const m = moment.isDate(date) ? this.moment(date, '0') : date.toDate ? this.moment(date.toDate(), '0') : this.moment(date, '0');
        return m.format('YYYY-MM-DD');
    }

    removerAcentos(text: string) {
        const mapaAcentosHex = {
            a: /[\xE0-\xE6]/g,
            e: /[\xE8-\xEB]/g,
            i: /[\xEC-\xEF]/g,
            o: /[\xF2-\xF6]/g,
            u: /[\xF9-\xFC]/g,
            c: /\xE7/g,
            n: /\xF1/g,
            A: /[\xC0-\xC6]/g,
            E: /[\xC8-\xCB]/g,
            I: /[\xCC-\xCF]/g,
            O: /[\xD2-\xD6]/g,
            U: /[\xD9-\xDC]/g,
            C: /\xC7/g,
            N: /\xD1/g,
        };

        for (const letra in mapaAcentosHex) {
            if (Object.prototype.hasOwnProperty.call(mapaAcentosHex, letra)) {
                const expressaoRegular = mapaAcentosHex[letra];
                text = text.replace(expressaoRegular, letra);
            }
        }

        return text;
    }

    abrirWhats(arg: { phone: string, message?: string }) {
        const url = `https://api.whatsapp.com/send?phone=${arg.phone}&text=${arg.message || ''}`;
        window.open(url, '_blank');
    }

    sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    dataURIToBlob(dataURI) {
        dataURI = dataURI.replace(/^data:/, '');

        const type = dataURI.match(/image\/[^;]+/);
        const base64 = dataURI.replace(/^[^,]+,/, '');
        const arrayBuffer = new ArrayBuffer(base64.length);
        const typedArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < base64.length; i++) {
            typedArray[i] = base64.charCodeAt(i);
        }

        return new Blob([arrayBuffer], { type });
    }

    dataURLtoFile(dataurl, filename) {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);

        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    /**
     * Returns a random integer between min (inclusive) and max (inclusive).
     * The value is no lower than min (or the next integer greater than min
     * if min isn't an integer) and no greater than max (or the next integer
     * lower than max if max isn't an integer).
     * Using Math.round() will give you a non-uniform distribution!
     */
    getRandomInt(min, max): number {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }


}
