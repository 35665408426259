import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from '../features/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {


    constructor(
        private auth: AuthService,
        private router: Router,
    ) { }


    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        const isLogged = await this.auth.isLogged();

        return isLogged || this.router.createUrlTree(['login'], { queryParams: { redirectUrl: state.url } });
    }
    async canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        const isLogged = await this.auth.isLogged();

        return isLogged || this.router.createUrlTree(['login'], { queryParams: { redirectUrl: state.url } });
    }

    async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean | UrlTree> {
        const isLogged = this.auth.isLogged();

        if (isLogged) {
            return true;
        }
        const fullPath = segments.reduce((path, currentSegment) => `${path}/${currentSegment.path}`, '');
        return this.router.createUrlTree(['login'], { queryParams: { redirectUrl: fullPath } });
    }

}
