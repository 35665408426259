import { Component, HostBinding, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';

import { Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppService } from './app.service';

@Component({
    selector: 'app-root',
    styles: [``],
    template: `
        <div *ngIf='!loaded' class='flex absolute top-0 left-0 bottom-0 right-0 justify-center align-middle'>
            <div class='m-auto grid grid-cols-2 gap-4 items-center'>
                <img class='w-12 h-12 ml-auto' src="/assets/logo/azp-logo_imagem.svg" alt="Logo Enterprise Platform">
                <div class='flex flex-col'>
                    <span><strong>Enterprise Platform</strong></span>
                    <span>Carregando...</span>
                </div>
            </div>
        </div>
    <router-outlet *ngIf='loaded'></router-outlet>
    `
})
export class AppComponent implements OnDestroy {

    @HostBinding('class') classes = '';
    @HostBinding('attr.app-version') appVersionAttr = environment.version;

    loaded = false;
    _destroyed = new Subject<void>();

    constructor(
        app: AppService,
        router: Router,
        title: Title,
    ) {

        title.setTitle('Enterprise - Azoup')
        app.init(this._destroyed)
            .then(() => router.initialNavigation())
            .then(async () => {
                const user = app.getCurrentUser();
                if (!user) {
                    await router.navigate(['login']);
                    return;
                }

                if (!user.emailVerified) {
                    await router.navigate(['login']);
                    return;
                }
            });

        app.observeNavigation()
            .pipe(takeUntil(this._destroyed))
            .subscribe(event => {
                if (!(event instanceof NavigationStart)) {
                    this.loaded = true;
                }
                if (!environment.production) {
                    if (event instanceof NavigationStart)
                        console.clear();
                    console.log({ event, url: (event as any).url });
                }
            });
    }

    ngOnDestroy(): void {
        this._destroyed.next();
        this._destroyed.complete();
    }

}
