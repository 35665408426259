import { NgxFirebaseService } from '@azoup/ngx-firebase';
import { UsuarioRolesType } from '@azoup/ngx-firebase-auth';
import { BehaviorSubject, Observable } from 'rxjs';
import { OrganizacaoDoc } from '../../_firebase/organizacao/organizacao.doc';
import { OrganizacaoUsuarioRolesType } from '../../_firebase/organizacao/usuario/organizacao-usuario-roles.type';
import { OrganizacaoUsuarioDoc } from '../../_firebase/organizacao/usuario/organizacao-usuario.doc';
import { UsuarioDoc } from '../../_firebase/usuario/usuario.doc';


export type TOrganizacaoPerfil = 'gestor' | 'profissional' | 'cliente' | 'no user' | 'system';

export type TOrgUsuarioRoles = UsuarioRolesType & OrganizacaoUsuarioRolesType;

export const RolesDefault: TOrgUsuarioRoles = {
    ADMIN: false,
    cliente: false,
    gestor: false,
    vendedor: false,
    profissional: false
};

export interface ICurrentUser {
    user: UsuarioDoc;
    organizacaoUser: OrganizacaoUsuarioDoc;
    organizacao: OrganizacaoDoc;
    roles: () => UsuarioRolesType & OrganizacaoUsuarioRolesType;
    clear: () => void;
}


export class CurrentUser implements ICurrentUser {
    private statusSubject: BehaviorSubject<CurrentUser>;
    private firebase: NgxFirebaseService;

    private userDoc: UsuarioDoc;
    private organizacaoUserDoc: OrganizacaoUsuarioDoc;
    private organizacaoDoc: OrganizacaoDoc;


    get status(): Observable<CurrentUser> {
        return this.statusSubject.asObservable();
    }


    set user(user: UsuarioDoc) {
        if (this.userDoc) { this.userDoc.offLine(); }
        this.userDoc = user;
        if (!this.user) {
            this.emit();
            return;
        }

        this.user.onLine();
        this.emit();
    }
    get user(): UsuarioDoc {
        return this.userDoc;
    }

    set organizacaoUser(organizacaoUser: OrganizacaoUsuarioDoc) {
        if (this.organizacaoUserDoc) { this.organizacaoUserDoc.offLine(); }
        this.organizacaoUserDoc = organizacaoUser;
        if (!this.organizacaoUser) {
            this.emit();
            return;
        }
        this.organizacaoUser.data.ultimoAcesso = this.firebase.serverTimestamp();
        this.organizacaoUser.onLine();
        this.organizacaoUser.save();
        this.emit();
    }
    get organizacaoUser(): OrganizacaoUsuarioDoc {
        return this.organizacaoUserDoc;
    }

    set organizacao(organizacao: OrganizacaoDoc) {
        if (this.organizacaoDoc) { this.organizacaoDoc.offLine(); }
        this.organizacaoDoc = organizacao;
        if (!this.organizacao) {
            this.emit();
            return;
        }
        this.organizacao.onLine();
    }

    get organizacao(): OrganizacaoDoc {
        return this.organizacaoDoc;
    }

    get perfil(): TOrganizacaoPerfil {
        if (!this.organizacaoUser) {
            return 'no user';
        }
        return this.organizacaoUser.data.roles.gestor ? 'gestor' :
            this.organizacaoUser.data.roles.profissional ? 'profissional' :
                this.organizacaoUser.data.roles.cliente ? 'cliente' :
                    null;
    }

    constructor(
        firebase: NgxFirebaseService
    ) {
        this.firebase = firebase;
        this.statusSubject = new BehaviorSubject<CurrentUser>(null);
    }

    roles(): TOrgUsuarioRoles {
        const roles = Object.assign({}, RolesDefault);
        if (!this.user) { return roles; }
        Object.keys(this.user.data.roles || {}).map(k => roles[k] = this.user.data.roles[k]);
        if (!this.organizacaoUser) { return roles; }
        Object.keys(this.organizacaoUser.data.roles || {}).map(k => roles[k] = this.organizacaoUser.data.roles[k]);
        return roles;
    }

    clear(): void {
        if (this.user) { this.user.offLine(); }
        if (this.organizacao) { this.organizacao.offLine(); }
        if (this.organizacaoUser) { this.organizacaoUser.offLine(); }
        this.user = undefined;
        this.organizacaoUser = undefined;
        this.organizacao = undefined;
    }

    emit(): void {
        if (this.statusSubject) {
            this.statusSubject.next(this);
        }
    }

}
