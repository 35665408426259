import firebase from 'firebase/compat/app';
import { NgxFirebaseDoc } from '@azoup/ngx-firebase';
import { OrgFreteSchema } from './frete.schema';

export class OrgFreteDoc extends NgxFirebaseDoc<OrgFreteSchema>{

    constructor(snap?: firebase.firestore.QueryDocumentSnapshot | firebase.firestore.DocumentSnapshot | firebase.firestore.DocumentChange) {
        super(snap);
    }
}
