import { NgxFirebaseDoc } from '@azoup/ngx-firebase';
import firebase from 'firebase/compat/app';
import { OrganizacaoAplicacaoSchema } from './organizacao-aplicacao.schema';


export class OrganizacaoAplicacaoDoc extends NgxFirebaseDoc<OrganizacaoAplicacaoSchema> {

    constructor(snap?: firebase.firestore.QueryDocumentSnapshot) {
        super(snap);
    }


}
